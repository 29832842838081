<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">스크립트 변경 관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
         <div>
          <div class="form-inp sm">
            <v-select
              v-model="aspCustKey"
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택하세요"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="SEARCH_USE_NEWTY"
              :items="SEARCH_USE_TY"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택하세요"
              label="사용구분"
            >
              <template v-slot:label>
                사용구분
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="변경일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                @change="startDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                :min="dates[0]"
                @change="endDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
         <div class="form-inp sm ml-2">
           <v-select
               v-model="searchCode"
               :items="this.mixin_common_code_get(this.commonCode, 'SYS002', '전체')"
               outlined
               hide-details
               placeholder="선택하세요"
           >
             <template v-slot:label>
               분류구분
             </template>
           </v-select>
         </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="CHNG_REQ"
              :items="this.mixin_common_code_get(this.commonCode, 'SCP002', '전체')"
              outlined
              hide-details
              placeholder="선택하세요"
            >
              <template v-slot:label>
                변경상태
              </template>
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
              class="form-inp full"
              outlined
              hide-details
              placeholder="분류구분내용"
              label="스크립트명"
              v-model="searchCont"
              @keyup.enter="getList"
            >
            </v-text-field>
          </div>
          <div class="form-inp ml-2">
          </div>
         </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="getList"> 조회 </v-btn>
          </div>
       </div>
    </div>
    <!-- 상담스크립트 변경리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex mt-3">
        상담스크립트 변경리스트
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDeny')" outlined class="btn-etc ml-2" v-on:click="showAlertDel">요청거부</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAccess')" outlined class="btn-point ml-2" v-on:click="showAlertReg">승인</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="276px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="ROW_NUMBER"
          hide-default-footer
          class="grid-default"
          fixed-header
          :items-per-page="30"
          :page.sync="page"
          calculate-widths
          @page-count="pageCount = $event"
          @click:row="tableClick"
          show-select
          :item-class="isActiveRow"
          v-model="CHK_SCP"
        >
          <template v-slot:item.REG_DTIM="{ item }" >
            <div>{{ dateFormat(item.REG_DTIM) }}</div>
          </template>
        </v-data-table>

        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption">
          총 <strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>
    </div>

    <!-- 변경 스크립트 -->
    <div class="box-wrap d-flex">
      <div class="col-6">
        <h2 class="tit-h2 mt-3">변경전 스크립트내용</h2>
        <div class="box-ct">
          <v-textarea
            v-model="CHNG_BF_CNTN"
            no-resize
            outlined
            disabled
          ></v-textarea>
        </div>

      </div>
      <div class="col-6">
        <h2 class="tit-h2 mt-3">변경후 스크립트내용</h2>
        <div class="box-ct">
          <v-textarea
            v-model="CHNG_AF_CNTN"
            no-resize
            outlined
            disabled
          ></v-textarea>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";

export default {
  mixins: [mixin],
  name: "MENU_M110603", //name은 'MENU_' + 파일명 조합
  components: {},
  data() {
    return {
      menu1: false,
      aspCustKey: "",
      searchCont: "",
      searchCode: "",
      CHNG_BF_CNTN: "",
      CHNG_AF_CNTN: "",

      selectedRow: [],

      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "ROW_NUMBER",
          width: "70px",
          sortable: true,
        },
        { text: '회사명', value: 'ASP_NEWCUST_KEY',align: 'center', sortable: true, },
        { text: "변경일시", value: "REG_DTIM", align: "center", sortable: true, width: "160px" },
        { text: '변경사용자', value: 'REQ_USER_NM',align: 'center', sortable: true, },
        { text: '스크립트분류명', value: 'LVL_NO_NM',align: 'center', sortable: true, width: "120px"},
        { text: '사용구분', value: 'USE_TY_NM',align: 'center', sortable: true},
        { text: '변경전 스크립트명', value: 'CHNG_BF_TIT',align: 'left', sortable: true, },
        { text: '변경후 스크립트명', value: 'CHNG_AF_TIT',align: 'left', sortable: true, },
        { text: '스크립트변경이력번호', value: 'CHNG_HIST_NO',align: 'center', sortable: true, width: "170px" },
        { text: '스크립트 변경상태', value: 'CHNG_REQ_NM',align: 'center', sortable: true, width: "150px" },
      ],

      commonCode: [],
      commonCodeArr: ["SYS002","SCP002"],

      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,

      SCP_NO:'',
      SEARCH_USE_TY:[
        {CD:"", CD_NM:"전체"}
      ],
      SEARCH_USE_NEWTY:"",

      CHK_SCP:[],
      CHNG_REQ:'',
    };
  },
  async mounted() {
    this.commonCode = await this.mixin_common_code_get_all(this.commonCodeArr);
    let codeName = ["SCP001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.SEARCH_USE_TY = [...this.SEARCH_USE_TY, ...common_code[0].group_value];
  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    gridSelected(){

    },
    async getList(){
      if(this.aspCustKey == '' || this.aspCustKey == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      }

      // 변경 스크립트 리스트 조회
      let listUrl = "/api/phone/script/change-manage/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = listUrl;
      requestData.headers["SERVICE"] = "phone.script.change-manage.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0]; //시작일
      requestData.sendData["END_DATE"] = this.dates[1]; //종료일
      requestData.sendData["CNSL_DIV"] = this.searchCode; //분류명
      requestData.sendData["CHNG_REQ"] = this.CHNG_REQ; //검색어
      requestData.sendData["SEARCH_CONT"] = this.searchCont; //검색어
      requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;  //사용구분


      let response = await this.common_postCall(requestData);
      this.setList(response);
      //console.log(response);
    },
    setList(response){
      let header = response.HEADER;
      let data = response.DATA;

      // 정상조회
      if(!header.ERROR_FLAG){
        this.gridDataText = data;
        this.CHNG_BF_CNTN = "";
        this.CHNG_AF_CNTN = "";

        //선택된 row 초기화
        if(this.selectedRow != ""){
          this.activeRow(this.selectedRow,false);
          this.selectedRow = "";
        }

      } else {
        this.gridDataText = [];
      }
    },
    tableClick(value, row){
      this.CHNG_BF_CNTN = row.item.CHNG_BF_CNTN;
      this.CHNG_AF_CNTN = row.item.CHNG_AF_CNTN;

      this.SCP_NO = row.item.CHNG_HIST_NO;

    },

    dateFormat(date){
      //날짜 포멧
      if(!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");
      newDate = date.replace( /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1/$2/$3 $4:$5:$6');

      return newDate;

    },

    isActiveRow(item) {
      const activeClass =
        item.CHNG_HIST_NO === this.SCP_NO ? "active" : "";
      return activeClass;
    },

    showAlertReg(){
      if(this.CHK_SCP.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '변경요청을 완료하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYes1,
        callNo: this.callNo1,
      })
    },

    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });

      this.finReq('SUCCESS');
    },

    showAlertDel(){
      if(this.CHK_SCP.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '요청을 거부하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes2,
        callNo: this.callNo2,
      })
    },
    
    callYes2() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.finReq('CANT');
    },

    callNo2(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    finReq(req){
      let REQ_COUNT=0;
      for(let n=0;n<this.CHK_SCP.length;n++){
        if (this.CHK_SCP[n].CHNG_REQ == 'SUCCESS' || this.CHK_SCP[n].CHNG_REQ == 'CANT'){
          REQ_COUNT = REQ_COUNT+1;
        }
      }
      if(REQ_COUNT != "0"){
        this.common_alert("이미 변경이 완료된 스크립트가 있습니다.<br>변경이 완료되지 않은 스크립트를 선택해주세요.","error");
        return false;
      }
      for(let i=0;i<this.CHK_SCP.length;i++){
        api.post("api/phone/script/manage/fin-request",
        {
          ASP_NEWCUST_KEY: this.aspCustKey,
          SCRIPT_ID: this.CHK_SCP[i].SCRIPT_ID,
          SCRIPT_TIT: this.CHK_SCP[i].CHNG_AF_TIT,
          SCRIPT_RMK: this.CHK_SCP[i].CHNG_AF_CNTN,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          BEGIN_DATE: this.CHK_SCP[i].BEGIN_DATE,
          EOT_DATE: this.CHK_SCP[i].EOT_DATE,
          CHNG_REQ: req,
          CHNG_HIST_NO: this.CHK_SCP[i].CHNG_HIST_NO,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "phone.script.manage.fin-request",
          "METHOD" : "fin-request",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.success();
          this.getList();
          this.SCP_NO = '';
          this.CHNG_REQ = '';
          this.CHK_SCP=[];
        })
        .catch((err) => {
          alert(err);
        })
      }
    },
    
    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
};
</script>

<style></style>
